import { useWindowSize } from "@/shared/hooks/useWindowSize";
import Screen from "@/constants/screens";

import HeaderDesktop from "./components/HeaderDesktop";
import HeaderMobile from "./components/HeaderMobile";
import { Props } from "./types";

const Header = ({ theme, setShowModal }: Props): JSX.Element => {
  const { width } = useWindowSize();

  return width < Screen.DESKTOP_1280 ? (
    <HeaderMobile theme={theme} setShowModal={setShowModal} />
  ) : (
    <HeaderDesktop theme={theme} setShowModal={setShowModal} />
  );
};

export default Header;
