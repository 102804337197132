import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";

import { getNavItems } from "../utils";
import { SubLayout } from "@/containers/layout/LandingLayout";
import { Props } from "../types";
import routes from "@/constants/routes";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

const HeaderDesktop = ({ theme, setShowModal }: Props): JSX.Element => {
  const router = useRouter();

  const pageRoute = () => {
    if (router.pathname === routes.intermediaries) {
      return "intermediary";
    } else if (
      router.pathname === routes.landlords.root ||
      router.pathname === routes.landlords.dealCalculator ||
      router.pathname === routes.landlords.aboutUs ||
      router.pathname === routes.landlords.howItWorks
    ) {
      return "landlords";
    } else if (router.pathname.includes(routes.now.root)) {
      return "now";
    } else if (router.pathname === routes.landlords.register) {
      return "landlordsRedirect";
    } else {
      return "landing";
    }
  };

  const handleShowPopup = () => {
    if (!!setShowModal) {
      setShowModal(true);
    }
  };

  const actionButton = (route = routes.landlords.register || routes.landlords.register) => {
    switch (route) {
      case routes.landlords.root:
        return (
          <a
            className={`flex w-11/12 text-center fixed xl:inline-block bottom-6 items-center justify-center font-bold px-4 py-2 text-base whitespace-nowrap transition duration-300 ease-in-out transform rounded shadow absolute-center-x xl:left-0 xl:translate-x-0 overflox-x-hidden xl:relative xl:bottom-auto xl:pb-3 h-11 xl:w-auto mr-2 ${
              theme === "ivory" || pageRoute() === "landlords"
                ? "bg-landing-cherry text-white"
                : theme === "light"
                ? "bg-landing-orange text-white"
                : "bg-landing-orange text-landing-blue "
            } hover:scale-105 cursor-pointer
           `}
            onClick={() => handleShowPopup()}
          >
            Get Started
          </a>
        );
      case routes.landlords.aboutUs:
        return (
          <a
            className={`flex w-11/12 text-center fixed xl:inline-block bottom-6 items-center justify-center font-bold px-4 py-2 text-base whitespace-nowrap transition duration-300 ease-in-out transform rounded shadow absolute-center-x xl:left-0 xl:translate-x-0 overflox-x-hidden xl:relative xl:bottom-auto xl:pb-3 h-11 xl:w-auto mr-2 ${
              theme === "ivory" || pageRoute() === "landlords"
                ? "bg-landing-cherry text-white"
                : theme === "light"
                ? "bg-landing-orange text-white"
                : "bg-landing-orange text-landing-blue "
            } hover:scale-105 cursor-pointer
               `}
            onClick={() => setShowModal(true)}
          >
            Get Started
          </a>
        );
      case routes.landlords.howItWorks:
        return (
          <a
            className={`flex w-11/12 text-center fixed xl:inline-block bottom-6 items-center justify-center font-bold px-4 py-2 text-base whitespace-nowrap transition duration-300 ease-in-out transform rounded shadow absolute-center-x xl:left-0 xl:translate-x-0 overflox-x-hidden xl:relative xl:bottom-auto xl:pb-3 h-11 xl:w-auto mr-2 ${
              theme === "ivory" || pageRoute() === "landlords"
                ? "bg-landing-cherry text-white"
                : theme === "light"
                ? "bg-landing-orange text-white"
                : "bg-landing-orange text-landing-blue "
            } hover:scale-105 cursor-pointer
                 `}
            onClick={() => setShowModal(true)}
          >
            Get Started
          </a>
        );
      case routes.landlords.dealCalculator:
        return (
          <a
            className={`flex w-11/12 text-center fixed xl:inline-block bottom-6 items-center justify-center font-bold px-4 py-2 text-base whitespace-nowrap transition duration-300 ease-in-out transform rounded shadow absolute-center-x xl:left-0 xl:translate-x-0 overflox-x-hidden xl:relative xl:bottom-auto xl:pb-3 h-11 xl:w-auto mr-2 ${
              theme === "ivory" || pageRoute() === "landlords"
                ? "bg-landing-cherry text-white"
                : theme === "light"
                ? "bg-landing-orange text-white"
                : "bg-landing-orange text-landing-blue "
            } hover:scale-105 cursor-pointer
                 `}
            onClick={() => setShowModal(true)}
          >
            Get Started
          </a>
        );
      case routes.now.root:
        return (
          <Link href={routes.application.root}>
            <a
              className={`flex w-11/12 text-center fixed xl:inline-block bottom-6 items-center justify-center font-bold px-4 py-2 text-base whitespace-nowrap transition duration-300 ease-in-out transform rounded shadow absolute-center-x xl:left-0 xl:translate-x-0 overflox-x-hidden xl:relative xl:bottom-auto xl:pb-3 h-11 xl:w-auto mr-2 ${
                theme === "ivory"
                  ? "bg-landing-cherry text-white"
                  : theme === "light"
                  ? "bg-landing-orange text-white"
                  : "bg-landing-orange text-landing-blue "
              } hover:scale-105 cursor-pointer
             `}
            >
              Sign me up!
            </a>
          </Link>
        );
      case routes.now.howItWorks:
        return (
          <Link href={routes.application.root}>
            <a
              className={`flex w-11/12 text-center fixed xl:inline-block bottom-6 items-center justify-center font-bold px-4 py-2 text-base whitespace-nowrap transition duration-300 ease-in-out transform rounded shadow absolute-center-x xl:left-0 xl:translate-x-0 overflox-x-hidden xl:relative xl:bottom-auto xl:pb-3 h-11 xl:w-auto mr-2 ${
                theme === "ivory"
                  ? "bg-landing-cherry text-white"
                  : theme === "light"
                  ? "bg-landing-orange text-white"
                  : "bg-landing-orange text-landing-blue "
              } hover:scale-105 cursor-pointer
               `}
            >
              Sign me up!
            </a>
          </Link>
        );
      case routes.now.searchHomes:
        return (
          <Link href={routes.application.root}>
            <a
              className={`flex w-11/12 text-center fixed xl:inline-block bottom-6 items-center justify-center font-bold px-4 py-2 text-base whitespace-nowrap transition duration-300 ease-in-out transform rounded shadow absolute-center-x xl:left-0 xl:translate-x-0 overflox-x-hidden xl:relative xl:bottom-auto xl:pb-3 h-11 xl:w-auto mr-2 ${
                theme === "ivory"
                  ? "bg-landing-cherry text-white"
                  : theme === "light"
                  ? "bg-landing-orange text-white"
                  : "bg-landing-orange text-landing-blue "
              } hover:scale-105 cursor-pointer
                 `}
            >
              Sign me up!
            </a>
          </Link>
        );
      case routes.now.faqs:
        return (
          <Link href={routes.application.root}>
            <a
              className={`flex w-11/12 text-center fixed xl:inline-block bottom-6 items-center justify-center font-bold px-4 py-2 text-base whitespace-nowrap transition duration-300 ease-in-out transform rounded shadow absolute-center-x xl:left-0 xl:translate-x-0 overflox-x-hidden xl:relative xl:bottom-auto xl:pb-3 h-11 xl:w-auto mr-2 ${
                theme === "ivory"
                  ? "bg-landing-cherry text-white"
                  : theme === "light"
                  ? "bg-landing-orange text-white"
                  : "bg-landing-orange text-landing-blue "
              } hover:scale-105 cursor-pointer
                   `}
            >
              Sign me up!
            </a>
          </Link>
        );
      case routes.landlords.register:
        return;
      default:
        return (
          <Link
            href={
              theme === "ivory"
                ? `${publicRuntimeConfig?.BROKER_PORTAL_URL}/sign-up`
                : routes.application.root
            }
          >
            <a
              className={`flex w-11/12 text-center fixed xl:inline-block bottom-6 items-center justify-center font-bold px-4 py-2 text-base whitespace-nowrap transition duration-300 ease-in-out transform rounded shadow absolute-center-x xl:left-0 xl:translate-x-0 overflox-x-hidden xl:relative xl:bottom-auto xl:pb-3 h-11 xl:w-auto mr-2 ${
                theme === "ivory"
                  ? "bg-landing-cherry text-white"
                  : theme === "light"
                  ? "bg-landing-orange text-white"
                  : "bg-landing-orange text-landing-blue "
              } hover:scale-105 cursor-pointer
           `}
            >
              Sign up now
            </a>
          </Link>
        );
    }
  };

  const linkItems = getNavItems({
    withLogin:
      router.pathname !== routes.intermediaries &&
      router.pathname !== routes.landlords.root &&
      router.pathname !== routes.landlords.register &&
      router.pathname !== routes.landlords.dealCalculator &&
      router.pathname !== routes.landlords.aboutUs &&
      router.pathname !== routes.landlords.howItWorks,
    route: pageRoute(),
    isMobile: false,
  });

  const itemClassess = (hoverable: boolean) =>
    `mr-9 mr-8 2xl:mr-12 px-2 pt-2 border-opacity-0 border-landing-blue ${
      hoverable && "hover:border-opacity-100 border-landing-orange"
    } transition border-b duration-300`;

  return (
    <nav
      className={`relative z-50 w-full text-white py-4  ${
        theme === "dark" ? "bg-landing-blue" : theme === "light" ? "bg-white" : "bg-landing-ivory"
      }`}
    >
      <SubLayout allowOverflow>
        <div className="relative z-50 flex items-center justify-between w-full h-16 mt-0 x-5">
          <div className="flex items-start justify-start ">
            <Link
              href={
                pageRoute() === "landlords"
                  ? routes.landlords.root
                  : pageRoute() === "now"
                  ? routes.now.root
                  : "/"
              }
            >
              <a
                className={`relative w-32 h-8 xl:w-52 ${
                  pageRoute() === "landlords" || pageRoute() === "now" ? "xl:h-16" : "xl:h-12"
                } `}
              >
                <Image
                  src={
                    pageRoute() === "landlords"
                      ? "/shared/keyzy-horizontal-landlords.svg"
                      : pageRoute() === "now" && theme === "dark"
                      ? "/now/keyzy-now-main-logo.svg"
                      : pageRoute() === "now" && theme === "light"
                      ? "/now/keyzy-now-main-logo-light.svg"
                      : theme === "dark"
                      ? "/shared/keyzy-horizontal.svg"
                      : "/shared/keyzy-horizontal-white.svg"
                  }
                  alt="keyzy logo"
                  layout="fill"
                  priority
                />
              </a>
            </Link>
          </div>

          <ul className="relative flex flex-row items-center w-full z-10 xl:justify-end xl:flex-1 xl:flex-row">
            {linkItems?.map((item, idx) => (
              <li
                className={`${itemClassess(router.pathname !== item.href)} ${
                  idx === linkItems?.length - 1 &&
                  router.pathname !== routes.landlords.register &&
                  router.pathname !== routes.now.root &&
                  router.pathname !== routes.now.howItWorks &&
                  router.pathname !== routes.now.searchHomes &&
                  router.pathname !== routes.now.faqs &&
                  "after:content-[''] h-full relative after:h-full after:absolute-center-y  after:w-[1px] after:-right-4 2xl:after:-right-5 after:bg-landing-darkGray after:transform after:scale-y-125 after:opacity-70"
                } ${
                  item.href === routes.customerReviews &&
                  publicRuntimeConfig.SHOW_CUSTOMERS_REVIEW_MENU !== "1" &&
                  "hidden"
                }
                ${
                  router.pathname === item.href &&
                  "border-b border-opacity-100 border-landing-orange"
                }
                `}
                key={idx}
              >
                {(!item.items && (
                  <Link href={item.href}>
                    <a className="relative flex flex-col mx-auto w-fit" href={item.href}>
                      <span
                        className={`inline-block relative text-sm  3xl:text-base  font-semibold pb-2 ${
                          theme === "dark" ? "text-white" : "text-landing-blue"
                        }`}
                      >
                        {item.caption}
                      </span>
                      <span
                        className={`h-[1px] w-full transform scale-125 mt-1 absolute-center-x bottom-0 `}
                      ></span>
                    </a>
                  </Link>
                )) || (
                  <div className="group/main relative cursor-pointer">
                    <div className="flex flex-col mx-auto w-fit">
                      <span
                        className={`inline-block relative text-sm  3xl:text-base  font-semibold pb-2 ${
                          theme === "dark" ? "text-white" : "text-landing-blue"
                        }`}
                      >
                        {item.caption}
                        <span className="ml-2">
                          <Image
                            src="/budget-calculator/chevron-orange-bottom.svg"
                            width={11}
                            height={8}
                            alt="down chevron"
                            className={`transition-all duration-300 ease-in-out group-hover/main:rotate-180 rotate-0`}
                          />
                        </span>
                      </span>
                      <span className={"h-[1px] w-full mt-1 absolute-center-x bottom-0"}></span>
                    </div>
                    <div
                      className={`absolute -left-3 px-3 min-w-[115px] mt-px opacity-0 group-hover/main:flex cursor-pointer group-hover/main:opacity-100 pt-2 flex-col ${
                        theme === "dark"
                          ? "bg-landing-blue"
                          : theme === "light"
                          ? "bg-white"
                          : "bg-landing-ivory"
                      }`}
                    >
                      {item.items?.map((item, idx) => (
                        <Link href={item.href} key={idx}>
                          <a
                            className={`relative hidden opacity-0 group-hover/main:flex group-hover/main:animate-[rotateX_300ms_ease-in-out_forwards] flex flex-col w-fit mb-4 group/item first-of-type:pt-3`}
                            style={{ animationDelay: `${(idx + 1) * 60}ms` }}
                            href={item.href}
                            // target={item.href[0] === "/" ? "_self" : "_blank"}
                            rel="noreferrer"
                            data-kk={idx}
                          >
                            <span
                              className={`inline-block relative text-sm  3xl:text-base  font-semibold pb-2 ${
                                theme === "dark" ? "text-white" : "text-landing-blue"
                              }`}
                            >
                              {item.caption}
                            </span>
                            <span
                              className={`h-[1px] w-full group-hover/item:bg-landing-orange mt-1 absolute-center-x bottom-0  ${
                                router.pathname === item.href && "bg-landing-orange"
                              }`}
                            ></span>
                          </a>
                        </Link>
                      ))}
                    </div>
                  </div>
                )}
              </li>
            ))}
            {actionButton(router.pathname)}
          </ul>
        </div>
      </SubLayout>
    </nav>
  );
};

export default HeaderDesktop;
