import Hamburger from "@/atoms/Hamburger";
import Image from "next/image";
import Link from "next/link";
import { Props } from "../types";
import routes from "@/constants/routes";

export const LogoBar = ({
  theme,
  open,
  toggleMenu,
  className,
  pageRoute,
  setShowModal,
}: Props & {
  open: boolean;
  toggleMenu: () => void;
  className?: string;
  pageRoute: string;
}): JSX.Element => (
  <div
    className={`relative z-50 flex items-center justify-between w-full md:px-6 px-5 lg:px-11 ${className}`}
  >
    <div className="flex items-start justify-start">
      <Link
        href={
          pageRoute === "landlords"
            ? routes.landlords.root
            : pageRoute === "now"
            ? routes.now.root
            : "/"
        }
      >
        <a
          className={`relative xl:w-52 xl:h-12 ${
            pageRoute === "landlords" ? "h-16 w-48" : "h-8 w-32"
          }`}
        >
          <Image
            src={
              pageRoute === "landlords"
                ? "/shared/keyzy-horizontal-landlords.svg"
                : pageRoute === "now" && theme === "dark"
                ? "/now/keyzy-now-main-logo.svg"
                : pageRoute === "now" && theme === "light"
                ? "/now/keyzy-now-main-logo-light.svg"
                : theme === "dark"
                ? "/shared/keyzy-horizontal.svg"
                : "/shared/keyzy-horizontal-white.svg"
            }
            alt="keyzy logo"
            layout="fill"
            priority
          />
        </a>
      </Link>
    </div>

    <Hamburger onClick={toggleMenu} open={open} className="relative z-50 xl:hidden" theme={theme} />
  </div>
);
