import Link from "next/link";
import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import Image from "next/image";

import { useWindowSize } from "@/shared/hooks/useWindowSize";
import Screen from "@/constants/screens";
import { getNavItems } from "../utils";

import { Props } from "../types";
import routes from "@/constants/routes";
import { useScrollBlock } from "@/shared/hooks/useScrollBlock";
import { LogoBar } from "./LogoBar";
import getConfig from "next/config";

const HeaderMobile = ({ theme, setShowModal }: Props): JSX.Element => {
  const { width } = useWindowSize();
  const router = useRouter();
  const [open, setOpen] = useState(false);
  const [blockScroll, allowScroll] = useScrollBlock();
  const { publicRuntimeConfig } = getConfig();

  const [activeDD, setIsActive] = useState("");

  const handleClick = (active: string | undefined) => {
    setIsActive(active === undefined || active === activeDD ? "" : active);
  };

  const pageRoute = () => {
    if (router.pathname === routes.intermediaries) {
      return "intermediary";
    } else if (
      router.pathname === routes.landlords.root ||
      router.pathname === routes.landlords.dealCalculator ||
      router.pathname === routes.landlords.aboutUs ||
      router.pathname === routes.landlords.howItWorks
    ) {
      return "landlords";
    } else if (
      router.pathname === routes.now.root ||
      router.pathname === routes.now.faqs ||
      router.pathname === routes.now.howItWorks ||
      router.pathname === routes.now.searchHomes
    ) {
      return "now";
    } else if (router.pathname === routes.landlords.register) {
      return "landlordsRedirect";
    } else {
      return "landing";
    }
  };

  const actionButton = (route = routes.landlords.register || routes.landlords.register) => {
    switch (route) {
      case routes.landlords.root:
        return (
          <a
            className={`flex w-full relative mt-auto text-center items-center justify-center font-bold px-4 py-2 text-base whitespace-nowrap transition duration-300 ease-in-out transform rounded shadow  overflow-x-hidden  h-11 ${
              theme === "ivory" || pageRoute() === "landlords"
                ? "bg-landing-cherry text-white"
                : theme === "light"
                ? "bg-landing-orange text-white"
                : "bg-landing-orange text-landing-blue "
            } hover:scale-105 cursor-pointer
      bottom-8
`}
            onClick={() => setShowModal(true)}
          >
            Get started
          </a>
        );
      case routes.landlords.aboutUs:
        return (
          <a
            className={`flex w-full relative mt-auto text-center items-center justify-center font-bold px-4 py-2 text-base whitespace-nowrap transition duration-300 ease-in-out transform rounded shadow  overflow-x-hidden  h-11 ${
              theme === "ivory" || pageRoute() === "landlords"
                ? "bg-landing-cherry text-white"
                : theme === "light"
                ? "bg-landing-orange text-white"
                : "bg-landing-orange text-landing-blue "
            } hover:scale-105 cursor-pointer
        bottom-8
  `}
            onClick={() => setShowModal(true)}
          >
            Get started
          </a>
        );
      case routes.landlords.dealCalculator:
        return (
          <a
            className={`flex w-full relative mt-auto text-center items-center justify-center font-bold px-4 py-2 text-base whitespace-nowrap transition duration-300 ease-in-out transform rounded shadow  overflow-x-hidden  h-11 ${
              theme === "ivory" || pageRoute() === "landlords"
                ? "bg-landing-cherry text-white"
                : theme === "light"
                ? "bg-landing-orange text-white"
                : "bg-landing-orange text-landing-blue "
            } hover:scale-105 cursor-pointer
          bottom-8
    `}
            onClick={() => setShowModal(true)}
          >
            Get started
          </a>
        );
      case routes.landlords.howItWorks:
        return (
          <a
            className={`flex w-full relative mt-auto text-center items-center justify-center font-bold px-4 py-2 text-base whitespace-nowrap transition duration-300 ease-in-out transform rounded shadow  overflow-x-hidden  h-11 ${
              theme === "ivory" || pageRoute() === "landlords"
                ? "bg-landing-cherry text-white"
                : theme === "light"
                ? "bg-landing-orange text-white"
                : "bg-landing-orange text-landing-blue "
            } hover:scale-105 cursor-pointer
            bottom-8
      `}
            onClick={() => setShowModal(true)}
          >
            Get started
          </a>
        );
      case routes.now.root:
        return (
          <Link href={routes.application.root}>
            <a
              className={`flex w-11/12 text-center fixed xl:inline-block bottom-6 items-center justify-center font-bold px-4 py-2 text-base whitespace-nowrap transition duration-300 ease-in-out transform rounded shadow absolute-center-x xl:left-0 xl:translate-x-0 overflox-x-hidden xl:relative xl:bottom-auto xl:pb-3 h-11 xl:w-auto mr-2 ${
                theme === "ivory"
                  ? "bg-landing-cherry text-white"
                  : theme === "light"
                  ? "bg-landing-orange text-white"
                  : "bg-landing-orange text-landing-blue "
              } hover:scale-105 cursor-pointer
               `}
            >
              Sign me up!
            </a>
          </Link>
        );
      case routes.now.howItWorks:
        return (
          <Link href={routes.application.root}>
            <a
              className={`flex w-11/12 text-center fixed xl:inline-block bottom-6 items-center justify-center font-bold px-4 py-2 text-base whitespace-nowrap transition duration-300 ease-in-out transform rounded shadow absolute-center-x xl:left-0 xl:translate-x-0 overflox-x-hidden xl:relative xl:bottom-auto xl:pb-3 h-11 xl:w-auto mr-2 ${
                theme === "ivory"
                  ? "bg-landing-cherry text-white"
                  : theme === "light"
                  ? "bg-landing-orange text-white"
                  : "bg-landing-orange text-landing-blue "
              } hover:scale-105 cursor-pointer
                 `}
            >
              Sign me up!
            </a>
          </Link>
        );
      case routes.now.searchHomes:
        return (
          <Link href={routes.application.root}>
            <a
              className={`flex w-11/12 text-center fixed xl:inline-block bottom-6 items-center justify-center font-bold px-4 py-2 text-base whitespace-nowrap transition duration-300 ease-in-out transform rounded shadow absolute-center-x xl:left-0 xl:translate-x-0 overflox-x-hidden xl:relative xl:bottom-auto xl:pb-3 h-11 xl:w-auto mr-2 ${
                theme === "ivory"
                  ? "bg-landing-cherry text-white"
                  : theme === "light"
                  ? "bg-landing-orange text-white"
                  : "bg-landing-orange text-landing-blue "
              } hover:scale-105 cursor-pointer
                   `}
            >
              Sign me up!
            </a>
          </Link>
        );
      case routes.now.faqs:
        return (
          <Link href={routes.application.root}>
            <a
              className={`flex w-11/12 text-center fixed xl:inline-block bottom-6 items-center justify-center font-bold px-4 py-2 text-base whitespace-nowrap transition duration-300 ease-in-out transform rounded shadow absolute-center-x xl:left-0 xl:translate-x-0 overflox-x-hidden xl:relative xl:bottom-auto xl:pb-3 h-11 xl:w-auto mr-2 ${
                theme === "ivory"
                  ? "bg-landing-cherry text-white"
                  : theme === "light"
                  ? "bg-landing-orange text-white"
                  : "bg-landing-orange text-landing-blue "
              } hover:scale-105 cursor-pointer
                     `}
            >
              Sign me up!
            </a>
          </Link>
        );

      case routes.landlords.register:
        return;

      default:
        return (
          <>
            <Link
              href={
                theme === "ivory"
                  ? `${publicRuntimeConfig?.BROKER_PORTAL_URL}/sign-up`
                  : routes.dashboard.login
              }
            >
              <a
                onClick={() => setOpen(false)}
                className={`flex w-full relative mt-auto text-center border mb-3.5 items-center justify-center font-bold px-4 py-2 text-base whitespace-nowrap transition duration-300 ease-in-out transform rounded shadow  overflow-x-hidden  h-12 ${
                  theme === "ivory" ? "" : theme === "light" ? "" : ""
                } hover:scale-105
        bottom-8
`}
              >
                Login
              </a>
            </Link>
            <Link
              href={
                theme === "ivory"
                  ? `${publicRuntimeConfig?.BROKER_PORTAL_URL}/sign-up`
                  : routes.application.root
              }
            >
              <a
                onClick={() => setOpen(false)}
                className={`flex w-full relative mt-auto text-center items-center justify-center font-bold px-4 py-2 text-base whitespace-nowrap transition duration-300 ease-in-out transform rounded shadow  overflow-x-hidden  h-12 ${
                  theme === "ivory"
                    ? "bg-landing-cherry text-white"
                    : theme === "light"
                    ? "bg-landing-orange text-white"
                    : "bg-landing-orange text-landing-blue "
                } hover:scale-105
        bottom-8
`}
              >
                Sign up now
              </a>
            </Link>
          </>
        );
    }
  };

  const linkItems = getNavItems({
    withLogin:
      router.pathname !== routes.intermediaries &&
      router.pathname !== routes.landlords.root &&
      router.pathname !== routes.landlords.register &&
      router.pathname !== routes.landlords.dealCalculator &&
      router.pathname !== routes.landlords.aboutUs &&
      router.pathname !== routes.landlords.howItWorks,
    route: pageRoute(),
    isMobile: true,
  });

  const page = pageRoute();

  const toggleMenu = () => {
    setOpen((isMenuOpen) => !isMenuOpen);
  };

  useEffect(() => {
    if (width >= Screen.DESKTOP_1280) {
      setOpen(false);
      allowScroll();
    }

    if (width < Screen.DESKTOP_1280 && open) {
      blockScroll();
    }

    if (width < Screen.DESKTOP_1280 && !open) {
      allowScroll();
    }
  }, [width, open]);

  useEffect(() => {
    if (open) {
      allowScroll();
      setOpen(false);
    }
  }, [router]);

  useEffect(() => {
    if (!open) {
      return () => allowScroll();
    }
    return () => blockScroll();
  });

  return (
    <>
      <nav
        className={`z-50 w-full text-white overscroll-none overflow-hidden ${
          open ? "fixed inset-0" : ""
        } ${
          theme === "dark"
            ? "bg-landing-blue"
            : theme === "light"
            ? "bg-white text-landing-blue"
            : "bg-landing-ivory"
        }
       h-full`}
      >
        {open ? (
          <div
            className={`w-full relative h-full overflow-scroll flex flex-col ${
              theme === "dark"
                ? "bg-landing-blue"
                : theme === "light"
                ? "bg-white"
                : "bg-landing-ivory"
            } overflow-hidden ${open ? "block" : ""}
            transition duration-300
            ${open ? "opacity-100 z-50 pointer-events-auto" : "opacity-0 -z-1 pointer-events-none"}
            `}
          >
            <LogoBar
              theme={theme}
              open={open}
              toggleMenu={toggleMenu}
              className="top-6"
              pageRoute={page}
              setShowModal={setShowModal}
            />

            <ul
              className={`flex relative xl:top-auto w-full h-full mb-10 mt-10 overflow-scroll px-5 md:px-20 flex-col text-center
        
      `}
            >
              {linkItems.map((item, idx) => (
                <li
                  className={`mb-7 md:mb-11 ${idx === 0 ? "mt-16" : ""} ${
                    item.href === routes.customerReviews &&
                    publicRuntimeConfig.SHOW_CUSTOMERS_REVIEW_MENU !== "1"
                      ? "hidden"
                      : ""
                  }`}
                  key={idx}
                >
                  {(!item.items && (
                    <Link href={item.href}>
                      <a className="relative flex flex-col mx-auto w-fit" href={item.href}>
                        <span
                          className={`inline-block relative text-sm font-semibold pb-2 ${
                            theme === "dark" ? "text-white" : "text-landing-blue"
                          }`}
                        >
                          {item.caption}
                        </span>
                        <span
                          className={`h-[1px] w-9/12 mt-1 absolute-center-x bottom-0  ${
                            router.pathname === item.href && "bg-landing-orange"
                          }`}
                        ></span>
                      </a>
                    </Link>
                  )) || (
                    <div
                      className={`group ${activeDD === item.caption ? "active" : ""} flex flex-col`}
                      onClick={() => handleClick(item.caption?.toString())}
                    >
                      <div className="relative flex flex-col mx-auto w-fit">
                        <span
                          className={`inline-block relative text-sm font-semibold pb-2 ${
                            theme === "dark" ? "text-white" : "text-landing-blue"
                          }`}
                        >
                          {item.caption}
                          <span className="ml-2">
                            <Image
                              src="/budget-calculator/chevron-orange-bottom.svg"
                              width={11}
                              height={6}
                              alt="down chevron"
                              className={`transition-all duration-300 ease-in-out group-[.active]:rotate-180 rotate-0`}
                            />
                          </span>
                        </span>
                        <span
                          className={`h-[1px] w-full hidden group-[.active]:flex mt-1 absolute-center-x bottom-0 bg-landing-orange`}
                        ></span>
                      </div>
                      <div
                        style={{
                          maxHeight: activeDD === item.caption ? "800px" : "0px",
                        }}
                        className="overflow-hidden flex flex-col transition-all duration-500 ease-in-out group-[.active]:mt-7"
                      >
                        {item.items?.map((item, idx) => (
                          <Link href={item.href} key={item.href}>
                            <a
                              className="relative flex flex-col mx-auto w-fit last:mb-0 mb-7 md:mb-11"
                              href={item.href}
                            >
                              <span
                                className={`inline-block relative text-sm font-semibold pb-2 ${
                                  theme === "dark" ? "text-white" : "text-landing-blue"
                                }`}
                              >
                                {item.caption}
                              </span>
                              <span
                                className={`h-[1px] w-9/12 mt-1 absolute-center-x bottom-0  ${
                                  router.pathname === item.href && "bg-landing-orange"
                                }`}
                              ></span>
                            </a>
                          </Link>
                        ))}
                      </div>
                    </div>
                  )}
                </li>
              ))}
            </ul>
            <div className="flex px-5 md:px-20 flex-col justify-center text-center">
              {actionButton(router.pathname)}
            </div>
          </div>
        ) : (
          <LogoBar
            theme={theme}
            open={open}
            toggleMenu={toggleMenu}
            className={`pt-6`}
            pageRoute={page}
            setShowModal={setShowModal}
          />
        )}
      </nav>
    </>
  );
};

export default HeaderMobile;
